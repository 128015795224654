@import "~react-image-gallery/styles/css/image-gallery.css";

html {
  overflow-y: overlay;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  flex-grow: 1;
  display: flex;
}

.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tech-icon:hover {
  transform: scale(1.1);
}

.social-icon:hover {
  transform: scale(1.2);
}

@media screen and (min-width: 0px) {
  .about-photo {
    width: 10em; height: auto;
  }
  .tech-icon {
    transform: scale(0.8);
  }
  .company-logo, .project-photo {
    transform: scale(0.7);
  }
}

@media screen and (min-width: 600px) {
  .about-photo {
    width: 12em; height: auto;
  }
  .tech-icon {
    transform: scale(0.9);
  }
  .company-logo, .project-photo {
    transform: scale(0.8);
  }
}

@media screen and (min-width: 900px) {
  .about-photo {
    width: 15em; height: auto;
  }
  .tech-icon {
    transform: scale(0.95);
  }
  .company-logo, .project-photo {
    transform: scale(0.9);
  }
}

@media screen and (min-width: 1200px) {
  .about-photo {
    width: 18em; height: auto;
  }
  .tech-icon {
    transform: scale(1);
  }
  .company-logo, .project-photo {
    transform: scale(1);
  }
}

.project-photo {
  transition: all .2s ease-in-out;

}

.project-photo:hover {
  transform: scale(1.5);
}

.show-background {
  background: url("./images/blob-scene.svg") no-repeat center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.Home, .About, .Projects, .Experience, .Contact {
  flex-grow: 1;
}

.expcard:nth-child(even) {
  background-color: #D7EBFF;
}

.success-alert {
  display: none;
  opacity: 0;
  padding: 20px;
  border-radius: 5px;
  background-color: #0bc581;
  color: white;
}

.error-alert {
  display: none;
  opacity: 0;
  padding: 20px;
  border-radius: 5px;
  background-color: #f44336;
  color: white;
}

.alert-enter {
  display: block;
  opacity: 0;
}
.alert-enter-active {
  display: block;
  opacity: 1;
  transition: opacity 300ms;
}
.alert-exit {
  display: block;
  opacity: 1;
}
.alert-exit-active {
  display: block;
  opacity: 0;
  transition: opacity 300ms;
}

.slick-next:before, .slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: black;
}

.carousel-item img {
  margin: 0 auto; /* this will align the image to center. */
  width: auto; /* for those images which don't have a width specified, but has a max-height will be auto adjusted */
}

.project-card:hover {
    -webkit-filter: brightness(70%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators /*, .carousel-indicators */ {
  filter: invert(35%) ;
}
